import "./src/styles/global.css";
//const React = require("react");

// Logs when the client route changes
export const onRouteUpdate = ({ location }) => {
  //console.log("new pathname", location.pathname);
};

// gatsby-browser.js
export const onClientEntry = () => {
  document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll("[__idm_id__]").forEach((el) => el.removeAttribute("__idm_id__"));
  });
};
