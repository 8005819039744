exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-begrepp-js": () => import("./../../../src/pages/begrepp.js" /* webpackChunkName: "component---src-pages-begrepp-js" */),
  "component---src-pages-begrepp-wp-insurance-school-slug-js": () => import("./../../../src/pages/begrepp/{wpInsuranceSchool.slug}.js" /* webpackChunkName: "component---src-pages-begrepp-wp-insurance-school-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-releases-js": () => import("./../../../src/pages/press-releases.js" /* webpackChunkName: "component---src-pages-press-releases-js" */),
  "component---src-pages-press-releases-wp-press-release-slug-js": () => import("./../../../src/pages/press-releases/{wpPressRelease.slug}.js" /* webpackChunkName: "component---src-pages-press-releases-wp-press-release-slug-js" */),
  "component---src-pages-school-category-wp-insurance-school-category-slug-js": () => import("./../../../src/pages/school-category/{wpInsuranceSchoolCategory.slug}.js" /* webpackChunkName: "component---src-pages-school-category-wp-insurance-school-category-slug-js" */),
  "component---src-pages-testimonials-category-wp-testimonial-category-slug-js": () => import("./../../../src/pages/testimonials-category/{wpTestimonialCategory.slug}.js" /* webpackChunkName: "component---src-pages-testimonials-category-wp-testimonial-category-slug-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-testimonials-wp-testimonial-slug-js": () => import("./../../../src/pages/testimonials/{wpTestimonial.slug}.js" /* webpackChunkName: "component---src-pages-testimonials-wp-testimonial-slug-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-forsakringsskolan-js": () => import("./../../../src/templates/forsakringsskolan.js" /* webpackChunkName: "component---src-templates-forsakringsskolan-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-homev-1-js": () => import("./../../../src/templates/homev1.js" /* webpackChunkName: "component---src-templates-homev-1-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-om-oss-js": () => import("./../../../src/templates/om-oss.js" /* webpackChunkName: "component---src-templates-om-oss-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-sa-fungerar-det-js": () => import("./../../../src/templates/sa-fungerar-det.js" /* webpackChunkName: "component---src-templates-sa-fungerar-det-js" */),
  "slice---src-components-layout-footer-js": () => import("./../../../src/components/layout/footer.js" /* webpackChunkName: "slice---src-components-layout-footer-js" */),
  "slice---src-components-layout-header-js": () => import("./../../../src/components/layout/header.js" /* webpackChunkName: "slice---src-components-layout-header-js" */)
}

